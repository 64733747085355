import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'; // Import der Umgebungsvariablen
import { Observable } from 'rxjs';
import { AuthService } from '../user/auth.service';
import { jwtDecode } from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class PermissionService {

	private baseUrl = environment.apiUrl; // Verwendung der API-URL aus den Umgebungsvariablen

	constructor(
		private http: HttpClient,
		private authService: AuthService
	) {

	}

	getAllPermissions(): Observable<any> {
		return this.http.get<any[]>(`${this.baseUrl}/permission/getAll`);
	}

	hasPermission(permission: string): boolean {
		this.authService.checkTokenValidity(); // Token vor der Prüfung überprüfen
	
		const token = localStorage.getItem('token');
		if (token) {
			const decodedToken: any = jwtDecode(token);
	
			// Wenn der Benutzer isSuperAdmin ist, hat er alle Rechte
			if (decodedToken.userRights.includes('isSuperAdmin')) {
				return true;
			}
	
			// Prüfe, ob der Benutzer das angeforderte Recht besitzt
			return decodedToken.userRights.includes(permission);
		}
		return false;
	}
	
	  
}
